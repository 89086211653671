import React from "react"
import { Box, Flex, Avatar, Text } from "theme-ui"
import SEO from "../components/SEO"
import ContentSection from "../components/ContentSection"
import Layout from "../components/Layout"

// images
import NatarajanImage from "../../images/Natarajan.png"
import VeerababuImage from "../../images/Veerababu.png"
import PageHeader from "../components/PageHeader"

interface FacultyMemberProps {
  image: string
  name: string
  description: string
}

const FacultyMember = (props: FacultyMemberProps): JSX.Element => {
  const { image, name, description } = props
  return (
    <Box m={4} sx={{ textAlign: "center" }}>
      <Avatar src={image} />
      <Text mt={1} color="primary">
        {name}
      </Text>
      <Text>{description}</Text>
    </Box>
  )
}

const FacultyPage = ({ data }) => (
  <Box>
    <SEO title="Faculty" />
    <Layout>
      <PageHeader>
        <Text sx={{ fontSize: 5, fontWeight: "bold", textAlign: "center" }}>
          Highly Experienced Faculty
        </Text>
        <Text sx={{ fontSize: 5, fontWeight: "bold", textAlign: "center" }}>
          With UPSC Experience
        </Text>
      </PageHeader>
      <ContentSection
        header="Faculty"
        subHeader="Eminent personalities who are experts in their domain are the guest lecturers"
        noHeaderBorder={true}
        body={
          <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
            <FacultyMember
              image={NatarajanImage}
              name="Dr.Natarajan IPS"
              description="Ex TNPSC Chairman"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
          </Flex>
        }
      />
      <ContentSection
        header="Guest Lecturers"
        subHeader="Eminent personalities who are experts in their domain are the guest lecturers"
        noHeaderBorder={true}
        body={
          <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
            <FacultyMember
              image={NatarajanImage}
              name="Dr.Natarajan IPS"
              description="Ex TNPSC Chairman"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
            <FacultyMember
              image={VeerababuImage}
              name="Dr. Veerababu"
              description="UPSC"
            />
          </Flex>
        }
      />
    </Layout>
  </Box>
)

export default FacultyPage
